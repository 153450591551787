import React, { Component } from "react";
import { connect } from "react-redux";
import SpinRound from "../../../src/assets/images/Round.png";
import SpinIcon from "../../../src/assets/images/SpinIcon.png";
import "./Home.scss";
import * as API from "../../services";
import PopUp from "../../components/PopUp/PopUp";
import "reactjs-popup/dist/index.css";
import { sortDesc } from "../../common/sortDateDESC";
import { IS_DEV } from "../../utils";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PopUpState: false,
      popUpLoginState: false,
      PopUpStatus: "",
      styleSpinWheel: {},
      voucher: {},
      codes: [],
      rewardCampaignHistory: {},
      prize: "",
      rewardPrize: {},
      code: undefined,
      timeInfo: "",
      descriptionInfo: "",
      checkSpin: false,
      memberCardNo: "",
      description: "",
      emptyRewards: false,
    };
    this.btnSpin = React.createRef();
    this.handleSpin = this.handleSpin.bind(this);
    // this.renderVoucher = this.renderVoucher(this);
  }

  handleSpin(random) {
    const styleSheets = document.styleSheets[document.styleSheets.length - 1];
    const nameAni = `spin-wheel-${Math.round(Math.random() * 180)}`;
    const keyFrames = `@-webkit-keyframes ${nameAni} {
            from {transform: rotate(0deg)}
            to {transform: rotate(360deg)}
        }`;
    styleSheets.insertRule(keyFrames, styleSheets.cssRules.length);
    const styleData = {
      animationName: nameAni,
      animationTimingFunction: "linear",
      animationDuration: "1.3s",
      animationIterationCount: "infinite",
    };
    this.setState({ styleSpinWheel: styleData });
    setTimeout(() => {
      const styleSheets = document.styleSheets[document.styleSheets.length - 1];
      styleSheets.removeRule(styleSheets.cssRules.length - 1);

      // The number of 8 is the amount of voucher items in wheel
      // 45deg = 360deg / amount of voucher items

      const distanceOfSpinWheel =
        Math.floor(Math.random() * 45) === 0
          ? 5
          : Math.floor(Math.random() * 45) - 5;

      const rotate = 8 * 360 + (-360 * random) / 8 + distanceOfSpinWheel;

      const stopWheel = {
        transform: `rotate(${rotate}deg)`,
        transition: `transform 3s`,
      };
      this.setState({
        styleSpinWheel: stopWheel,
      });
    }, 3000);
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(window.location.search);
    let tokenLocal = query.get("access_token");
    if (!tokenLocal && !IS_DEV) {
      let domain = new URL(process.env.REACT_APP_LEVIS_PROFILE);
      domain = domain.hostname;
      window.location.href = `https://${domain}/Registration/Login.aspx`;
      return;
    }
    tokenLocal = IS_DEV
      ? "100kiet@mailinator.com"
      : await this.decodeEmail(tokenLocal);
    const member = await API.memberEnquiryAPI(tokenLocal);
    let memberCardNo = "";
    if (
      member.data &&
      member.data.CardLists &&
      member.data.CardLists.length > 0
    ) {
      memberCardNo = member.data.CardLists[0].CardNo;
      const codesAPI = await API.getCustomCodesAPI();
      const voucherAPI = await API.getVouchersAPI(memberCardNo);
      const newVouchers = sortDesc(voucherAPI.data);
      this.setState({
        voucher: newVouchers,
        codes: codesAPI.data.CustomCodes,
        time: codesAPI.data.CustomCodes[0].Description,
        description: codesAPI.data.CustomCodes[1].Description,
        memberCardNo: memberCardNo,
      });
    } else {
      this.setState({
        popUpLoginState: true,
      });
    }
  };

  decodeEmail = async (encodedString) => {
    var email = "";
    var keyInHex = encodedString.substr(0, 2);
    var key = parseInt(keyInHex, 16);

    for (var n = 2; n < encodedString.length; n += 2) {
      var charInHex = encodedString.substr(n, 2);
      var char = parseInt(charInHex, 16);
      var output = char ^ key;
      email += String.fromCharCode(output);
    }
    return email;
  };

  handlePrize = async () => {
    let listPrizeTemp = [];
    this.state.codes.forEach((item) => {
      const historyValue = this.state.rewardCampaignHistory[item.CustomCode];
      if (
        !historyValue ||
        (historyValue && historyValue === 99999) ||
        (historyValue && historyValue < item.Description)
      ) {
        listPrizeTemp.push(item);
      }
    });

    /* Hard code because of the requirements of projects */

    const voucher1 = listPrizeTemp[0];
    const voucher2 = listPrizeTemp[1];
    const voucher3 = listPrizeTemp[2];

    listPrizeTemp = [
      voucher1,
      voucher3,
      voucher2,
      voucher3,
      voucher1,
      voucher3,
      voucher2,
      voucher3,
    ].map((item, index) => ({
      ...item,
      autoId: index + 1,
    }));

    if (listPrizeTemp.length > 0) {
      const rd = Math.random();
      const ramdomTemp = Math.floor(rd * listPrizeTemp.length);
      const prize = listPrizeTemp[ramdomTemp];
      const positionPrize =
        listPrizeTemp.findIndex((x) => {
          return x.autoId === prize.autoId;
        }) + 1;

      this.setState({ rewardPrize: prize });
      this.handleSpin(positionPrize);
      this.setState({ emptyRewards: false });
    } else {
      this.setState({ rewardPrize: undefined });
      this.setState({ emptyRewards: true });
    }
  };

  updateVoucherActive = async () => {
    const voucherAPI = await API.getVouchersAPI(this.state.memberCardNo);
    const listPrize = await API.getCustomCodesAPI();
    const newVouchers = sortDesc(voucherAPI.data);
    this.setState({
      voucher: newVouchers,
      codes: listPrize.data.CustomCodes,
      rewardCampaignHistory: listPrize.data.RewardCampaignHistory,
    });
  };

  handleClick = async () => {
    if (!this.state.checkSpin) {
      this.setState({
        checkSpin: true,
      });
      await this.updateVoucherActive();
      if (this.state.voucher.TotalActiveVoucherCount > 0) {
        await this.handlePrize();
        if (this.state.rewardPrize) {
          const prizeAPI = await API.rewardCampaign(
            this.state.rewardPrize.CustomCode,
            this.state.rewardPrize.Description !== 99999,
            this.state.memberCardNo
          );

          let rewardStatus = "";
          if (prizeAPI.data.TransactionReferenceInfo) {
            rewardStatus = "SUCCESS";
            this.setState({
              prize: prizeAPI.data.TransactionReferenceInfo.CampaignName,
            });
            // await API.voucherRedemption(this.state.voucher.ActiveVoucherLists[0], this.state.memberCardNo)
            const voucherAPI = await API.getVouchersAPI(
              this.state.memberCardNo
            );
            const newVouchers = sortDesc(voucherAPI.data);
            this.setState({
              voucher: newVouchers,
            });
          } else if (prizeAPI.data.ReturnStatus === 7) {
            rewardStatus = "INACTIVE";
          }

          setTimeout(() => {
            this.setState({
              PopUpState: true,
              PopUpStatus: rewardStatus,
              checkSpin: false,
            });
          }, 1000);
        } else {
          if (this.state.emptyRewards) {
            this.setState({
              PopUpState: true,
              checkSpin: false,
              PopUpStatus: "INACTIVE",
            });
          }
        }
      }
    }
  };
  renderVoucher(item) {
    let name = item.Name + "";
    // let nameWithouVoucher = name.substring(0, name.indexOf("voucher"));
    // let voucher = name.substring(name.indexOf("voucher"), name.length);

    return (
      <li className="voucher-item">
        <p className="text-no-voucher">{name}</p>
        <p className="text-voucher">Voucher</p>
      </li>
    );
  }
  render() {
    const { styleSpinWheel } = this.state;
    const voucher1 = this.state.codes[0];
    const voucher2 = this.state.codes[1];
    const voucher3 = this.state.codes[2];

    return (
      <div className="home-wrapper">
        {this.state.PopUpState && (
          <PopUp
            statusMessage={this.state.PopUpStatus}
            chances={this.state.voucher.TotalActiveVoucherCount}
            item={this.state.rewardPrize.Name}
            onClick={() => this.setState({ PopUpState: false })}
          />
        )}
        {this.state.popUpLoginState && (
          <PopUp
            statusMessage={this.state.PopUpStatus}
            onClick={() => this.setState({ popUpLoginState: false })}
          />
        )}

        <div className="home-container">
          <div className="bg-container">
            <div className="round">
              <div className="icon-round-box">
                <div
                  className="box-round-img list-voucher"
                  style={styleSpinWheel}
                >
                  <img src={SpinRound} alt="" className="icon-round" />
                  <div
                    className="voucher-box"
                    style={{}}
                    onClick={this.handleClick}
                  >
                    {voucher1 && this.renderVoucher(voucher1)}
                    {voucher3 && this.renderVoucher(voucher3)}
                    {voucher2 && this.renderVoucher(voucher2)}
                    {voucher3 && this.renderVoucher(voucher3)}
                    {voucher1 && this.renderVoucher(voucher2)}
                    {voucher3 && this.renderVoucher(voucher3)}
                    {voucher2 && this.renderVoucher(voucher2)}
                    {voucher3 && this.renderVoucher(voucher3)}
                  </div>
                </div>
                <div
                  ref={this.btnSpin}
                  className="spin"
                  onClick={this.handleClick}
                >
                  <img src={SpinIcon} alt="" className="icon-spin" />
                </div>
              </div>
            </div>

            <div className="content">
              <p className="title">Spin The Wheel</p>
              <p className="description">
                Spin the wheel to get Levi's® vouchers worth up to RM100, <br />
                redeemable with a minimum spend of RM399.
                <br />
                <br />
                Tap the wheel to spin for your voucher. You can play from now
                until 28 February 2023. <br /> Vouchers will be automatically
                added to your profile.
                {/* <br />
                <br />
                How to play:
                <br />
                1. Login or sign up for free as a Levi's® Red Tab™ member.
                <br />
                2. Tap on the wheel to spin for your voucher.
                <br />
                3. Your voucher will be automatically added to your profile. Use
                it online or in-store by 28 Feb 2023. */}
              </p>
              <p className="chances">
                Spins left: {this.state.voucher.TotalActiveVoucherCount || 0}
              </p>
            </div>
          </div>

          <div className="footer-container">
            <div className="terms">
              <p className="terms-title">Terms and conditions:</p>
              <p className="terms-content">
                - Open to all new and existing Levi's® Red Tab™ Program members.
                <br />
                - All Blue and Silver tier members are entitled to 1x spin, Gold
                members are entitled to 2x spins. Playable from 1–28 February
                2023.
                <br />
                - No more spins will be given once the maximum is reached and/or
                beyond the campaign validity period.
                <br />
                - Voucher(s) awarded will be automatically added to your member
                profile on levi.com.my, valid on purchases made on levi.com.my
                and at Levi's® Boutique Stores (excluding Levi's® Outlet
                Stores).
                <br />
                - All issued voucher(s) are valid until 28 February 2023 and
                redeemable with a minimum purchase of RM399, excluding Levi's®
                Made & Crafted®, Levi's® Vintage Clothing and other special
                collaborations.
                <br />- Vouchers are not stackable with other vouchers or promos
                unless otherwise stated.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.admin.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
